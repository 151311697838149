export function submitForm(
  path: string,
  params: Partial<Record<string, string>>,
  method = 'post'
) {
  let form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params[key] !== undefined) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key]!;
      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}
