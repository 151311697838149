import {
  SentryService,
  KonakartService,
  AddressService,
  OrderService,
  NavigationService,
  TechnicianAvailabilityService,
  JSONConfigService,
  NotificationService,
  MyAccountSWR,
  MyAccountService,
  TrackerService,
  PrequalService,
  ServiceProviderService,
  VoiceService,
  FaqService,
  MyAccountV2Service,
  MyAccountV2SWR,
} from '@sky-tv-group/shared';
import {
  agent,
  kkAgent,
  addressAgent,
  orderAgent,
  agentNavigation,
  agentJSONConfig,
  myAccountAgent,
  agentTracker,
  agentPrequal,
  prequalAgent,
  agentVoice,
  agentFAQ,
  agentMyAccount,
  gqlAgentMyAccount,
} from '../agent';
import { APP_ENVIRONMENT, SENTRY_DENY_URLS, SENTRY_DSN } from '../config';

export const sentryService = new SentryService(SENTRY_DSN, APP_ENVIRONMENT, SENTRY_DENY_URLS);
export const konakartService = new KonakartService(kkAgent);
export const addressService = new AddressService(addressAgent);
export const orderService = new OrderService(orderAgent);
export const navService = new NavigationService(agentNavigation);
export const configService = new JSONConfigService(agentJSONConfig);
export const notificationService = new NotificationService(agentMyAccount);
export const technicianLookup = new TechnicianAvailabilityService(agent);
export const myAccountService = new MyAccountService(myAccountAgent);
export const myAccountV2Service = new MyAccountV2Service(agentMyAccount, gqlAgentMyAccount);
export const serviceProviderService = new ServiceProviderService(prequalAgent);
export const voiceService = new VoiceService(agentVoice);
export const faqService = new FaqService(agentFAQ);

export let trackerService = new TrackerService(agentTracker);
export let prequalService = new PrequalService(agentPrequal);

export const myAccountSWR = new MyAccountSWR(myAccountService, konakartService);
export const myAccountV2SWR = new MyAccountV2SWR(myAccountV2Service);
export const kkService = new KonakartService(agent);
