import {
  T_Product,
  productSkuIds,
  useAddUpdateBox,
  formatMoneyTwoDecimalPlaces,
  useIdentifyBox,
  useEligibilityOffers,
  Box,
  couponStoreApi,
  T_Coupon,
  couponCodes,
  SPLITIO_KEY,
  useSplitIO,
} from '@sky-tv-group/shared';
import { MarketingRectangle } from '../arrow-marketing/MarketingRectangle';
import React from 'react';
import { Checked, Unchecked } from '../icon';
import { Button } from '../button';

export interface ICEBoxCardSelectableProps {
  editingBox: Box | undefined;
  product: T_Product;
  handleProductSelection: (value: any) => void;
  isProductSelected: boolean;
  isMultiroom?: boolean;
  fromFaultSwap?: boolean;
}

const ICEBoxCardSelectable: React.FC<ICEBoxCardSelectableProps> = ({
  editingBox,
  product,
  handleProductSelection,
  isProductSelected,
  isMultiroom,
  fromFaultSwap,
}) => {
  const { newSkyBoxMonthly, skyPodOneOffFee } = useAddUpdateBox();
  const { legacyBox } = useIdentifyBox();
  const discountedBoxFeeIfAvailable = useEligibilityOffers().discountedBoxFeeIfAvailable();
  const arrowBoxMonthlyFee = newSkyBoxMonthly?.price0 || 0;

  const chosenOneoffWithOffer = editingBox?.products.find(
    p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary && p.price0 !== p.priceIncTax
  );
  const has01292 = couponStoreApi
    .getState()
    .coupons.find((c: T_Coupon) => c.couponCode === couponCodes.offer50WOShohoHindi);
  const isArrowBox = (boxSku: string) => {
    return boxSku.includes(productSkuIds.arrowBox.primary) || boxSku.includes(productSkuIds.arrowBoxBlack.primary);
  };

  const isSkyPod = (boxSku: string) => {
    return boxSku.includes(productSkuIds.skyPod.primary);
  };

  const isSkyBox = product.sku === productSkuIds.skyBoxCharge.primary;

  const price = (product: T_Product) => {
    //Sky pod should show one off fee price
    if (product.sku === productSkuIds.skyPod.primary && skyPodOneOffFee) return skyPodOneOffFee.price0;

    return product.price0;
  };
  //Using this flow to show nsb and pod for zero dollors
  const [nsbAndPodForZero] = useSplitIO(SPLITIO_KEY.SKYWEB_ACQ_HIDE_MNTH_ONEOF_CHRGE);

  return (
    <div
      key={product.id}
      className={`cursor-pointer relative pt-8 minContent snap-center ${isMultiroom && 'min-w-full'}`}
      onClick={() => handleProductSelection(product.sku)}>
      {(isArrowBox(product.sku) || isSkyPod(product.sku)) && !fromFaultSwap && (
        <MarketingRectangle
          mRectColor={'linear-gradient(270deg, #9100D4 -5.35%, #9100D4 -0.07%, #9100D4 57.93%, #9100D4 126.47%)'}
          mRectTag={'Limited Time Only'}
          mRectTagClasses={'text-white text-center font-bold text-xl md:text-sm lg:text-xl py-1 pl-3 pr-12'}
          top={-24}
          left={0}
        />
      )}
      <div
        className={`flex flex-col flex-shrink-0 justify-around h-full border border-gray-dark rounded-md  relative
				hover:border-gray-copy  ${isProductSelected ? 'border-gray-copy' : undefined}`}>
        {/* {isArrowBox(product.sku) && isMultiroom && <MarketingRectangle mRectTag="Recommended" />} */}
        {product.sku !== productSkuIds.arrowBoxRecording.primary ? (
          <div className="absolute top-0 right-0" style={{ marginTop: '10px', marginRight: '10px' }}>
            {isProductSelected ? <Checked /> : fromFaultSwap ? <></> : <Unchecked />}
          </div>
        ) : (
          ''
        )}
        <div
          className="w-full h-auto rounded-t-md bg-gray-cool flex items-center justify-center flex-1"
          style={{ height: '16.666667%' }}>
          {/* If we're only upgrading recording, use legacy box image. */}
          {product.sku === productSkuIds.arrowBoxRecording.primary && (
            <img
              className="w-full max-w-xs h-full rounded-t-md block object-contain object-center"
              alt={product.name}
              src="https://static.sky.co.nz/sky/box/recording/my_sky.png"
            />
          )}

          {/* Otherwise, use box image. */}
          {product.sku !== productSkuIds.arrowBoxRecording.primary && (
            <img
              className="w-full max-w-xs h-full rounded-t-md block object-contain object-center"
              alt={product.name}
              src={
                product.sku === '616' ? 'https://static.sky.co.nz/sky/box/sky_pod_with_remote.png' : product.custom1
              }
            />
          )}
        </div>
        <div
          className={`flex flex-col p-7 sm:p-6 md:p-2 text-center items-center  flex-1 ${isSkyBox ? 'flex-1 ' : ''}`}>
          <p
            className={`sky-h5-black md:sky-h7-black mt-4 ${
              isArrowBox(product.sku) && !fromFaultSwap ? 'purple-linear-gradient-text' : 'text-midnight'
            }`}>
            {product.name === 'My Sky' ? 'My Sky Recording' : product.name}
          </p>
          {/* {product.custom10 ? (
            <div className="">
              <img src={product.custom10} alt="box images" />
            </div>
          ) : (
            <div className="">
              <span className="text-sm font-bold"> Recording available with My Sky</span>
            </div>
          )} */}
          <div>
            {product.sku === productSkuIds.arrowBoxRecording.primary ? (
              <div className="">
                <span className="text-sm font-bold"> Add Recording to your Sky Box</span>
              </div>
            ) : fromFaultSwap ? (
              <div className="md:sky-sub sky-text-midnight font-bold mt-4">
                <span>My Sky Recording compatible</span>
              </div>
            ) : (
              ''
            )}
          </div>
          {/* Arrow Payment options  */}
          {nsbAndPodForZero &&
          (product.sku == productSkuIds.arrowBox.primary || product.sku === productSkuIds.skyPod.primary) ? (
            <div
              className={`text-midnight flex flex-col items-center flex-grow ${fromFaultSwap ? 'mt-4' : 'mt-20'}`}
              style={{ marginTop: !isArrowBox(product.sku) ? '20px' : '' }}>
              <div
                className=" text-midnight flex items-center flex-grow relative"
                style={{ marginBottom: '8px', top: !isArrowBox(product.sku) ? '30px' : '' }}>
                <small className=" align-top sky-text-red line-through " style={{ fontSize: '10px' }}>
                  {isArrowBox(product.sku)
                    ? `$${formatMoneyTwoDecimalPlaces(
                        chosenOneoffWithOffer ? chosenOneoffWithOffer.price0 : discountedBoxFeeIfAvailable.originalPrice
                      )} `
                    : `${formatMoneyTwoDecimalPlaces(price(product))}`}
                </small>
                <small className="sky-h7-black align-top" style={{ fontSize: 10 }}>
                  $
                </small>
                <span className="sky-h5-bold"> 0.00</span>
                <small className="align-bottom pt-2">/one-off charge</small>
              </div>
            </div>
          ) : isArrowBox(product.sku) ? (
            <div className=" text-midnight flex flex-col items-center flex-grow">
              <div className=" left-30 top-200 rounded-md back_box  pt-2">
                <span style={{ fontSize: '13px' }} className="sky-h7-reg mt-4p">
                  One-off charge
                </span>
                &nbsp;
                {has01292 && (
                  <small className=" align-top sky-text-red line-through " style={{ fontSize: '10px' }}>
                    {`$${formatMoneyTwoDecimalPlaces(
                      chosenOneoffWithOffer ? chosenOneoffWithOffer.priceIncTax : discountedBoxFeeIfAvailable.price
                    )} `}
                  </small>
                )}
                <small className="sky-h7-black align-top " style={{ fontSize: 10, marginTop: '2%' }}>
                  $
                </small>
                {!has01292 && (
                  <span className="sky-h5-bold inline-block">
                    {formatMoneyTwoDecimalPlaces(
                      chosenOneoffWithOffer ? chosenOneoffWithOffer.price0 : discountedBoxFeeIfAvailable.originalPrice
                    )}
                  </span>
                )}
                {has01292 && <span className="inline-block font-bold  ">100.00</span>}
              </div>
              <div className="bg-gray-copy rounded-full w-6 h-6 flex items-center justify-center py-1 orBox">
                <p className="text-white text-13px p-1">or</p>
              </div>
              <div className="left-30 top-239 rounded-md back_box">
                <span style={{ fontSize: '13px', paddingRight: '11px' }} className="sky-h7-reg mt-4p">
                  Monthly charge
                </span>
                &nbsp;
                <small className="sky-h7-black align-top" style={{ fontSize: 10, marginTop: '2%' }}>
                  $
                </small>
                <span className="sky-h5-bold inline-block mt-4p">
                  {' '}
                  {formatMoneyTwoDecimalPlaces(arrowBoxMonthlyFee)}
                </span>
                {/* <small className="align-bottom">/mth</small> */}
              </div>
            </div>
          ) : (
            <div className=" text-midnight flex-grow flex items-center" style={{ marginTop: '20px' }}>
              <small className="sky-h7-black align-top" style={{ fontSize: 10 }}>
                $
              </small>
              <span className="sky-h5-bold"> {formatMoneyTwoDecimalPlaces(price(product))}</span>
              <small className="align-bottom pt-2">{isSkyPod(product.sku) ? '/one-off charge' : '/mth'}</small>
            </div>
          )}
          <div>
            {product.sku === productSkuIds.arrowBoxRecording.primary ? (
              <div>
                {' '}
                <Button
                  className="addboxbtn mt-4"
                  variant="primary"
                  colorMode={isProductSelected ? 'pink' : 'pure'}
                  icon={isProductSelected ? 'check' : undefined}>
                  {isProductSelected ? 'Added' : 'Add'}
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ICEBoxCardSelectable };
