import { headerChannelInterceptor, kkServiceResponseInterceptor } from '@sky-tv-group/shared';
import axios, { AxiosRequestConfig } from 'axios';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import {
  BACKEND_ENDPOINT,
  KONAKART_SERVICE_URL,
  ADDRESS_SERVICE_URL,
  NAVIGATION_URL,
  JSON_CONFIG_URL,
  ADDRESS_PREQUAL_ENDPOINT,
  FAQ_ENDPOINT,
  GRAPHQL_URL,
} from './config';
import { sentryService } from './services';
import { getClient } from './store/authStore/authStore';
import { setContext } from '@apollo/client/link/context';
let requestInterceptor = async (config: AxiosRequestConfig) => {
  try {
    const accessToken = await getClient()?.getTokenSilently();

    config.headers['authorization'] = config.headers['authorization'] ?? `Bearer ${accessToken}`;

    return config;
  } catch (e) {
    // Only allow public routes to still be hit without token
    if (config.url?.indexOf('/public/') === -1) {
      // eslint-disable-next-line no-throw-literal
      throw 'Trying to access private route without token';
    }

    return config;
  }
};

const responseInterceptor = async (err: any) => {
  if (err.response) {
    sentryService.captureException(err, { contexts: { response: err.response } });
  }
  return Promise.reject(err);
};

export let agent = axios.create({ baseURL: BACKEND_ENDPOINT });
export let myAccountAgent = axios.create({ baseURL: BACKEND_ENDPOINT });
export let kkAgent = axios.create({ baseURL: KONAKART_SERVICE_URL });
export let addressAgent = axios.create({ baseURL: ADDRESS_SERVICE_URL });
export let orderAgent = axios.create({ baseURL: BACKEND_ENDPOINT });
export let agentNavigation = axios.create({ baseURL: NAVIGATION_URL });
export let agentJSONConfig = axios.create({ baseURL: JSON_CONFIG_URL });
export const prequalAgent = axios.create({ baseURL: ADDRESS_PREQUAL_ENDPOINT });
export const agentVoice = axios.create({ baseURL: BACKEND_ENDPOINT });
export const agentFAQ = axios.create({ baseURL: FAQ_ENDPOINT });

orderAgent.interceptors.request.use(headerChannelInterceptor('WEB-ICE'));
orderAgent.interceptors.response.use(resp => resp, responseInterceptor);
myAccountAgent.interceptors.request.use(requestInterceptor);
agent.interceptors.response.use(resp => resp, responseInterceptor);
kkAgent.interceptors.response.use(resp => resp, responseInterceptor);
kkAgent.interceptors.response.use(resp => resp, kkServiceResponseInterceptor);
const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});
const authLink = setContext(async (_, { headers }) => {
  try {
    const accessToken = await getClient()?.getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  } catch (e) {
    // @ts-ignore
    if (e.error === 'login_required') {
      return {
        headers: {
          ...headers,
          authorization: '',
        },
      };
    }

    return null;
  }
});
export const agentTracker = axios.create({
  baseURL: BACKEND_ENDPOINT,
});
agentTracker.interceptors.request.use(requestInterceptor);
agentTracker.interceptors.response.use(resp => resp, responseInterceptor);
export const gqlAgentMyAccount = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
export const agentMyAccount = axios.create({
  baseURL: BACKEND_ENDPOINT,
});
export const agentPrequal = axios.create({
  baseURL: BACKEND_ENDPOINT,
  validateStatus: function() {
    return true;
  },
});
agentMyAccount.interceptors.request.use(requestInterceptor);
agentMyAccount.interceptors.response.use(resp => resp, responseInterceptor);
