import {
  summaryStrings,
  messages,
  categoryIds,
  productSkuIds,
  offerLinkLabel,
  linkPhoneNumber,
} from '@sky-tv-group/shared';

const APP_ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV!;
const BACKEND_ENDPOINT = process.env.REACT_APP_ENDPOINT!;
//const BACKEND_ENDPOINT = 'http://localhost:4001/'!;
const ADDRESS_PREQUAL_ENDPOINT = process.env.REACT_APP_ENDPOINT!;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN_CART!;
const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL!;
const ADDRESS_SERVICE_URL = process.env.REACT_APP_ADDRESS_LOOKUP_ENDPOINT!;
/** The Java one. */
const KONAKART_SERVICE_URL = process.env.REACT_APP_KONAKART_ENDPOINT!;
/** Terms and conditions url */
const TERMS_AND_CONDITIONS = process.env.REACT_APP_TERMS_AND_CONDITIONS!;
const TERMS_AND_CONDITIONS_DOMESTIC = process.env.REACT_APP_TERMS_AND_CONDITIONS_DOMESTIC!;
const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY!;
/** Cart Captcha */
const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY!;
const CAPTCHA_ENABLED = process.env.REACT_APP_CAPTCHA_ENABLED!;
const IS_CAPTCHA_ENABLED = CAPTCHA_ENABLED === '1';

const NAVIGATION_URL = process.env.REACT_APP_LINKS_ENDPOINT!;
const ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL!;
const JSON_CONFIG_URL = process.env.REACT_APP_JSON_CONFIG_URL!;

const DOMAIN = process.env.REACT_APP_DOMAIN!;

const SPLIT_AUTHORIZATION_KEY = process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY!;
const SPLIT_KEY = 'skyweb';

const FAQ_ENDPOINT = process.env.REACT_APP_FAQ_ENDPOINT!;

// Sentry deny urls as arrays of RegExps
const SENTRY_DENY_URLS = (process.env.REACT_APP_SENTRY_DENY_URLS || '')
  .split(',')
  .filter(s => !!s)
  .map(s => new RegExp(s, 'i'));

/** Account Details */
const ageMessage = 'You must be at least 18 years old to subscribe.';
const rentMessage = "You will need the landlord's written permission to install Sky.";

const defaultICOMSPin = '1234';
const orderDetailsProductCableType = 'cable';

const BUNDLE_OFFER_BANNER = 'Get Sky Bundle offer Banner';

const skyWifiBoosterProductName = 'Sky WiFi Booster';
const AUTH0_LOGOUT_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL!;

/** Just a page in sky.co.nz. */
const BB_REGISTER_URL = process.env.REACT_APP_BB_REGISTER_URL!;

const Banks = [
  { value: '', label: 'Select Bank...' },
  { value: '0', label: 'ANZ' },
  { value: '1', label: 'ASB' },
  { value: '2', label: 'BNZ' },
  { value: '3', label: 'CITIBANK' },
  { value: '4', label: 'HSBC' },
  { value: '5', label: 'ICBC' },
  { value: '6', label: 'KIWIBANK' },
  { value: '7', label: 'TSB' },
  { value: '8', label: 'WESTPAC' },
  { value: '9', label: 'Others' },
];

export const paymentExpressLogo = 'https://static.sky.co.nz/sky/icons/ic-windcave.png';

export const msgs = {
  payment: {
    oneoff: {
      success: {
        title: 'Thank You! <br />Your transaction was successful',
        subtitle: 'Your payment has been processed. Please keep a note of your payment reference which is below.',
      },
      failure: {
        title: 'Oh no! <br />Sorry we encountered a problem',
        subtitle: 'Your order could not be processed at this time, Please try again later or contact us',
      },
    },
    paperlessBilling: {
      success: 'Your paperless billing preferences have been updated successfully.',
      failure: `Sorry, there was a problem updating your paperless billing preferences. Please try again or contact the Sky team on ${linkPhoneNumber}.`,
    },
    mop: {
      success: {
        title: 'Thank You! <br />Your transaction was successful',
        subtitle:
          'A recurring payment has been set up for this account. Please check the Billing preferences section in your My Account to view your next payment date. <br/> A 1cent holding fee will be added to your account to verify your credit card. This will disappear from your account within 7 days.',
      },
      failure: {
        title: 'Oh no! <br />Sorry we encountered a problem',
        subtitle:
          'Your recurring payment set up could not be processed at this time, Please try again later or contact us.<br/>You can always setup recurring payments under the My Account page',
      },
    },
    mopDirectDebit: {
      success: 'Your recurring payment has been set up!',
      failure: `Sorry, there was a problem setting up your direct debit. Please try again or contact the Sky team on ${linkPhoneNumber}.`,
    },
  },
};

export {
  messages,
  summaryStrings,
  categoryIds,
  productSkuIds,
  BACKEND_ENDPOINT,
  KONAKART_SERVICE_URL,
  ADDRESS_SERVICE_URL,
  TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS_DOMESTIC,
  SENTRY_DSN,
  CAPTCHA_KEY,
  IS_CAPTCHA_ENABLED,
  NAVIGATION_URL,
  JSON_CONFIG_URL,
  PRIVACY_POLICY,
  ACCOUNT_URL,
  ageMessage,
  rentMessage,
  offerLinkLabel,
  DOMAIN,
  SPLIT_AUTHORIZATION_KEY,
  SPLIT_KEY,
  defaultICOMSPin,
  orderDetailsProductCableType,
  APP_ENVIRONMENT,
  SENTRY_DENY_URLS,
  AUTH0_LOGOUT_URL,
  BB_REGISTER_URL,
  ADDRESS_PREQUAL_ENDPOINT,
  FAQ_ENDPOINT,
  Banks,
  skyWifiBoosterProductName,
  GRAPHQL_URL,
  BUNDLE_OFFER_BANNER,
};
