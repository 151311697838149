import { useRef, useState } from 'react';
import { useForm } from 'react-jeff';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  AddressPrequalification,
  cableCouponCodeOnA12MonthContract,
  CouponTypes,
  createCustomerDetails,
  createRequestBody,
  CustomerDetails,
  messages,
  OrderDetails,
  orderStoreApi,
  SPLITIO_KEY,
  T_Coupon,
  useAccountDetailsStore,
  useAnalytics,
  useBroadband,
  useBroadbandDeliveryStore,
  useCouponStore,
  useKeyThingsToKnowField,
  useOrderStore,
  useSplitIO,
  useTermsAndConditions,
  useToastContainer,
  useVoiceStore,
  useUrlParamsStore,
  PACKAGE_TYPE,
  ORDER_ERROR_MSGS,
  useNewAcquisitionOrderStore,
  useUpgradableBoxesStore,
  useEligibilityOffers,
  ELIGIBILITY_ACQUISITION_TYPE,
  VTVMigrationType,
  IArrowDelivery,
  useFreeProductStore,
  sessionStorage,
  submitForm,
} from '@sky-tv-group/shared';
import {
  addressService,
  orderService,
  prequalService,
  sentryService,
  trackerService,
  konakartService,
} from '../services';
import { IS_CAPTCHA_ENABLED } from '../config';
import history from '../history';
import { useProductInCart } from '@sky-tv-group/shared';

/**
 * Hook for BB SPA review page, includes logic for submitting orders
 *
 */
function useReview() {
  const [submitting, setSubmitting] = useState(false);
  const [useNewOrderEndpoint] = useSplitIO(SPLITIO_KEY.SKYWEB_USE_ORDER_PROCESSOR_ENDPOINT);
  const [accountDetails] = useAccountDetailsStore(state => [state.accountDetails]);
  const { prequalData } = useBroadbandDeliveryStore();
  const { addToast } = useToastContainer();
  const { coupons } = useCouponStore();
  const { acquisitionType, eligibilityId } = useEligibilityOffers();
  const upgradableBoxDetails = useUpgradableBoxesStore(s => s.details);
  const { freeProduct } = useFreeProductStore();
  const {
    selectedTuiAddress,
    setAccountNumber,
    setFirstName,
    setWorkOrderNumber,
    setPaymentURL,
    setEmail,
  } = useNewAcquisitionOrderStore();
  const { details, deliveryDetails, getPrequalData } = useBroadband(prequalService);
  const { order } = useOrderStore();
  const { orderCompleted } = useAnalytics();
  const voiceDetails = useVoiceStore(s => s.details);
  const { broadbandPhoneProducts } = useProductInCart();
  const { clearStore } = useUrlParamsStore();

  const handleNothingToSubmit = () => {
    addToast({
      title: 'Warning',
      message: 'You do not have anything to upgrade currently. Please add a new package first.',
      type: 'warning',
    });
  };

  const handleErrors = (error: any) => {
    const message = messages.problemWithOrder;
    if (error?.response?.status === 400) {
      addToast({
        title: 'Alert',
        message: ORDER_ERROR_MSGS.RETURNING_CUSTOMER,
        type: 'error',
      });
    } else {
      addToast({
        title: 'Alert',
        message: `${message}`,
        type: 'error',
      });
    }
  };

  const getOrderContext = (details: OrderDetails | undefined, customer: CustomerDetails | undefined) => {
    return {
      // additional contexts for orders
      contexts: {
        orderDetails: {
          products: JSON.stringify(details?.products),
        },
      },
      user: {
        email: customer?.email,
        name: `${customer?.firstName} ${customer?.lastName}`,
      },
    };
  };

  const captchaRef = useRef<ReCAPTCHA>();
  const generateCaptchaToken = async (): Promise<string> => {
    const captcha = captchaRef.current;
    if (captcha) {
      const token = await captcha.executeAsync();
      if (token) {
        // need to reset captcha otherwise secondary click doesn't seem to work properly and takes long time with null token
        captcha.reset();
        return token;
      }
    }
    return '';
  };

  /**
   * Add DTH 12mth contract if there's no other DTH contract (Applies only to BB + DTH bundle acquisition flow)
   */

  async function addDefaultCableCoupon(coupons: T_Coupon[]): Promise<T_Coupon[]> {
    if (coupons.length === 0) {
      let defaultCoupon = await konakartService.getCoupon(cableCouponCodeOnA12MonthContract);
      return [defaultCoupon!];
    }
    return coupons;
  }
  let [enableCyberSource] = useSplitIO(SPLITIO_KEY.PAYMENT_WITH_CYBER_SOURCE);
  const termsAndConditionsField = useTermsAndConditions();
  const keyThingsToKnowField = useKeyThingsToKnowField();
  const prequal = new AddressPrequalification(prequalData?.data);
  const form = useForm({
    fields: [termsAndConditionsField, keyThingsToKnowField],
    onSubmit: async () => {
      // we can use order products here since this is acquisition (new customer)
      // and that all products in orderStore is not yet subscribed
      const orderProducts = order?.orderProducts || [];
      const { yourDetail, installationDetail, propertyDetail, marketingEmails, paymentInfo } = accountDetails;

      if (orderProducts.length === 0) {
        handleNothingToSubmit();
      } else {
        setSubmitting(true);
      }

      const customerDetails = await createCustomerDetails(
        yourDetail,
        installationDetail,
        propertyDetail,
        addressService,
        marketingEmails,
        paymentInfo
      );
      let orderDetails: OrderDetails | undefined;
      let priceHoldCoupon: T_Coupon | undefined;

      if (orderProducts.length) {
        setSubmitting(true);
        try {
          /**
           * Silent Campaign logic:
           * UPGRADE to BB: NO BB contract. (Scenario handled within in MyAccount)
           * ACQUISITION BUNDLE: DTH 12 mth contract + BB No Contract
           * ACQUISITION BB ONLY: BB 12mth contract
           */
          let cableCoupons = coupons.filter(c => c.custom5 === CouponTypes.Cable);
          let dataVoiceCoupons = coupons.filter(
            c => c.custom5 === CouponTypes.Broadband || c.custom5 === CouponTypes.Voice
          );

          cableCoupons = await addDefaultCableCoupon(cableCoupons);

          const customer = {
            tuiAddressCode: selectedTuiAddress?.toString() ?? '',
            firstName: customerDetails.firstName,
            lastName: customerDetails.lastName,
            serviceContactEmail: propertyDetail.serviceContactEmail ?? customerDetails.email,
          };

          let arrowDeliveryDetail: IArrowDelivery | undefined = {
            contactName: `${customerDetails.firstName} ${customerDetails.lastName}`,
            mobileNumberAreaCode: yourDetail.phoneAreaCode,
            mobileNumberLineNumber: yourDetail.phoneTelephoneNumber,
            email: customerDetails.email,
            installationAddress: propertyDetail.address?.label ?? '',
            useDifferentDeliveryAddress: true,
            defaultDeliveryAddress: [],
            deliveryAddress: propertyDetail.deliveryAddress,
          };

          orderDetails = await createRequestBody(
            trackerService,
            addressService,
            details,
            deliveryDetails,
            orderProducts.map(op => op.product),
            [],
            customer,
            undefined,
            [...dataVoiceCoupons, ...cableCoupons],
            getPrequalData,
            upgradableBoxDetails,
            voiceDetails,
            undefined,
            undefined,
            false,
            arrowDeliveryDetail,
            '',
            acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone,
            acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone ? VTVMigrationType.STANDALONE : undefined
          );

          let order = orderStoreApi.getState().order!;

          order = {
            ...order,
            ...coupons.reduce(
              (acc, coupon) => {
                acc.couponDetails.push(coupon);
                if (freeProduct) {
                  acc.freeProduct.push(freeProduct);
                }
                return acc;
              },
              { couponDetails: [], freeProduct: [] } as any
            ),
          };

          const token = IS_CAPTCHA_ENABLED ? await generateCaptchaToken() : '';
          const response = await orderService.postNewOrder(
            order,
            customerDetails,
            orderDetails,
            token,
            !!useNewOrderEndpoint,
            eligibilityId
          );

          setAccountNumber(response?.accountNumber);
          setWorkOrderNumber(response?.initialOrderId);
          setPaymentURL(response?.paymentUrl ?? '');
          setFirstName(yourDetail.firstName);
          setEmail(customerDetails?.email);
          // analytics -- there is a possibility that the work order number is empty
          const workOrderNumber = response?.initialOrderId;
          const broadbandIsIncludedInCart = broadbandPhoneProducts!.length > 0;

          sessionStorage.setItem(
            'packageType',
            broadbandIsIncludedInCart ? PACKAGE_TYPE.DTH_BROADBAND : PACKAGE_TYPE.DTH
          );

          broadbandIsIncludedInCart
            ? orderCompleted(workOrderNumber, false, order, coupons, PACKAGE_TYPE.DTH_BROADBAND)
            : orderCompleted(workOrderNumber, false, order, coupons, PACKAGE_TYPE.DTH);

          if (response.errors?.length > 0) {
            const orderError = new Error(
              `Broadband order error ${response.errors[0].description}: ${response.errors[0].message}`
            );
            sentryService.captureException(orderError, getOrderContext(orderDetails, customerDetails));
          }
          if (response.paymentUrl) {
            //Successfully direct to  payment gateway  (after Submitting Order):
            let params = {
              access_key: response?.accssKey,
              profile_id: response?.profileId,
              transaction_uuid: response?.transactionUUID,
              signed_field_names: response?.signedFieldNames,
              unsigned_field_names: response?.unSignedFieldNames,
              signed_date_time: response?.signedDateTime,
              locale: response?.locale,
              transaction_type: response?.transactionType,
              reference_number: response?.referenceNumber,
              amount: response?.amount,
              currency: response?.currency,
              signature: response?.signature,
              bill_to_forename:"NoReal",
              bill_to_surname:"Name",
              bill_to_address_line1:"1295 Charleston Road",
              bill_to_address_city:"Mountain View",
              bill_to_address_state:"CA",
              bill_to_address_country:"US",
              bill_to_address_postal_code:"94043",
              bill_to_email:"null@cybersource.com"
            };
            enableCyberSource ? submitForm(response.paymentUrl, params) : window.location.href = response.paymentUrl;
          } else {
            history.push('/confirmation');
          }
        } catch (err) {
          handleErrors(err);
          sentryService.captureException(
            new Error('Broadband order error'),
            getOrderContext(orderDetails, customerDetails)
          );
        } finally {
          setSubmitting(false);
          // Clear URL Params Store so selectedSrc is reset to undefined on response received
          clearStore();
        }
      }
    },
  });

  return {
    form,
    prequal,
    submitting,
    captchaRef,
    accountDetails,
    broadbandDetails: details,
    broadbandDeliveryDetails: deliveryDetails,
    termsAndConditionsField,
    keyThingsToKnowField,
  };
}

export { useReview };
