/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { links, SPLITIO_KEY, useAccountDetails, useSplitIO } from '@sky-tv-group/shared';
import { addressService } from '../../../services';
import { DigiInputWrapper, Select } from '../../../components/form';
import { Field, Form } from 'react-jeff';
import { Banks } from '../../../config';
import { ArrowDown, ArrowUp, CheckIcon, ValidatedTextInput } from '@sky-tv-group/components';
interface Props {
  ddAccountNumber: Field<string[], string>;
  ddAccountName: Field<string, string>;
  ddBankName: Field<string, string>;
  ddTC: Field<string, string>;
  formValidation: Form<string>;
  ccAccountNumber: Field<string, string>;
  ccCVC: Field<string, string>;
  ccExpire: Field<string, string>;
  ccHolderNme: Field<string, string>;
  isAbandon?: boolean;
}
export let PaymentInfo = ({
  ddAccountNumber,
  ddAccountName,
  ddBankName,
  ddTC,
  ccAccountNumber,
  ccCVC,
  ccExpire,
  ccHolderNme,
  formValidation,
  isAbandon,
}: Props) => {
  const {
    paymentInfo,
    updateIsDD,
    updateDDAccountName,
    updateDDAccountNumber,
    updateDDBankName,
    updateCCAccountNumber,
    updateCCHolderName,
    updateCCExpire,
    updateCCCVC,
  } = useAccountDetails(addressService);

  const isDD = paymentInfo.isDD;
  const [showPaymentDetailsCheck, setShowPaymentDetailsCheck] = useState(false);

  const [enableCyberSourcePay] = useSplitIO(SPLITIO_KEY.PAYMENT_WITH_CYBER_SOURCE);

  useEffect(() => {
    if (isDD) {
      if (ddAccountNumber?.value && ddAccountName?.value && ddTC.value === 'checked') {
        setShowPaymentDetailsCheck(true);
      } else if (!ddAccountNumber?.value || !ddAccountName?.value || ddTC.value !== 'checked') {
        setShowPaymentDetailsCheck(false);
      }
    } else if (!isDD) {
      setShowPaymentDetailsCheck(true);
    }
  }, [ddAccountNumber?.value, ddAccountName?.value, ddTC.value, isDD, ddTC.value]);

  useEffect(() => {
    updateDDAccountNumber(ddAccountNumber.value);
  }, [ddAccountNumber.value]);
  useEffect(() => {
    updateDDAccountName(ddAccountName.value);
  }, [ddAccountName.value]);
  useEffect(() => {
    updateDDBankName(ddBankName.value);
  }, [ddBankName.value]);

  useEffect(() => {
    updateCCAccountNumber(ccAccountNumber.value);
  }, [ccAccountNumber.value]);
  useEffect(() => {
    updateCCCVC(ccCVC.value);
  }, [ccCVC.value]);
  useEffect(() => {
    updateCCExpire(ccExpire.value);
  }, [ccExpire.value]);
  useEffect(() => {
    updateCCHolderName(ccHolderNme.value);
  }, [ccHolderNme.value]);

  return (
    <>
      <div className="">
        <div className="flex flex-col md:flex-row md:flex-wrap p-6 md:p-0 md:py-6 border-gray-300 ">
          <div className="flex flex-row  md:w-full justify-between sky-h4-black md:sky-h6-black">
            <div>Your Payment Details</div>
          </div>
        </div>
        {
          <div className="flex flex-col mx-2">
            <div className="flex w-1/2 md:w-1/4 justify-between sky-text-midnight mb-4">
              <button
                onClick={() => updateIsDD(true)}
                className={cx('flex-1 p-1 font-bold', { 'sky-text-daylight border-b-4 border-offer': isDD })}
                type="button">
                Direct Debit
              </button>
              <button
                onClick={() => updateIsDD(false)}
                className={cx('flex-1 p-1 font-bold', { 'sky-text-daylight border-b-4 border-offer': !isDD })}
                type="button">
                Credit Card
              </button>
            </div>

            {isDD ? (
              <div className="flex flex-col md:flex-row md:flex-wrap ">
                {/* Bank Name */}
                <div className="w-full md:w-2/3">
                  <Select label="Bank name" field={ddBankName} id="bank-name" options={Banks} />
                </div>
                <div className="w-full md:w-2/3 pt-4">
                  <label className="block sky-label md:sky-label mb-2">Account Number</label>
                  <div data-recording-ignore="mask" className="-mx-2">
                    <DigiInputWrapper
                      type="input"
                      label="Account number"
                      labels={['Bank', 'Branch', 'Account number', 'Suffix']}
                      field={ddAccountNumber}
                      lengths={[2, 4, 7, 3]}
                    />
                  </div>
                </div>
                <div className=" mb-3 mt-4 w-full pt-2">
                  <div className="pb-4 w-full md:w-2/3 md:pr-2">
                    <label className="block sky-label md:sky-label mb-2">Account Holder Name</label>
                    <ValidatedTextInput
                      fieldValidation={ddAccountName}
                      formValidation={formValidation}
                      id="payment-cc-number"
                      className="w-full"
                      type="text"
                      onChange={str => {
                        ddAccountName.props.onChange(str);
                      }}
                      placeholder="John"
                    />
                  </div>
                </div>

                <div className="mb-6 w-full">
                  <label id="tc-label" htmlFor="tc">
                    <input
                      required
                      className={`w-6 h-6 md:w-4 md:h-4 mr-2 align-middle `}
                      id="tc"
                      type="checkbox"
                      checked={ddTC.value === 'checked'}
                      onChange={e => {
                        ddTC.setValue(e.target.checked ? 'checked' : '');
                      }}
                    />
                    <span className={`align-middle text-gray-darker leading-none sky-h7-reg md:sky-sub `}>
                       I have sole authority on the nominated account, and I agree to the{' '}
                      <a
                        href={links.conditionsOfAuthority}
                        className="text-blue-pure underline"
                        target="_blank"
                        rel="noopener noreferrer">
                        conditions of authority.
                      </a>
                    </span>
                  </label>
                  <p className=" pt-3  sky-h7-reg md:sky-sub mt-2">
                    Authority To Accept Direct Debits (not to operate as an assignment or agreement). If two or more
                    individuals are required to operate this account, then you will need to print, sign and send in a{' '}
                    <a
                      href={links.ddForm}
                      className="text-blue-pure underline"
                      target="_blank"
                      rel="noopener noreferrer">
                      direct debit form.
                    </a>
                  </p>
                  <p className="sky-h7-reg md:sky-sub mt-2">Authorisation Code 0202559</p>
                </div>
              </div>
            ) : (
              <div>
                <div className="mt-4 rounded-md border-l-6 border-blue-light bg-blue-cool inline-block px-40px py-4 sky-h6-reg mb-8">
                  {` You will be redirected to the ${
                    enableCyberSourcePay ? 'Cybersource' : 'Payment Express'
                  } website to set up your credit card payment after
                  confirming your order.`}
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
};
