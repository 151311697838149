import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { formatMoney, parseQuery, SPLITIO_KEY, useSplitIO } from '@sky-tv-group/shared';
import { Modal, Button } from '@sky-tv-group/components';

import { msgs, paymentExpressLogo } from '../../config';

type Callback = 'success' | 'failure';
type PaymentType = 'oneoff' | 'mop';

let getMsg = (callback: Callback, type: PaymentType) => {
  return msgs.payment[type][callback];
};
interface Props {
  callback: Callback;
  amount: string;
  type: PaymentType;
  DpsTxnRef: string;
}
let PaymentCallbackModal = ({ callback, amount, type, DpsTxnRef }: Props) => {
  let [modalIsOpen, setIsOpen] = useState(true);

  let amountStr = formatMoney(parseFloat(amount));

  let showAmount = callback === 'success' && type === 'oneoff';

  let { title, subtitle } = getMsg(callback as Callback, type as PaymentType);

  let close = () => {
    setIsOpen(false);
  };
  let [enableCyberSource] = useSplitIO(SPLITIO_KEY.PAYMENT_WITH_CYBER_SOURCE);
  return (
    <div>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onRequestClose={close}>
          <div className="p-2 flex flex-col items-center px-8 pb-8">
            <h4
              className="sky-h4 font-bold md:sky-h4 text-center py-4 "
              dangerouslySetInnerHTML={{ __html: title }}></h4>
            <p className="text-left md:text-center " dangerouslySetInnerHTML={{ __html: subtitle }}></p>
            <div className="m-6">
              <p className="text-center text-2xl font-bold">Transaction Reference</p>
              <p className="text-center text-2xl font-bold text-blue-light p-2">{DpsTxnRef}</p>
            </div>
            {showAmount && (
              <div className="m-6">
                <p className="text-center text-2xl font-bold">Amount Settlement</p>
                <p className="text-center text-2xl font-bold text-blue-light p-2">{amountStr}</p>
              </div>
            )}

           {enableCyberSource || <img className="w-48 h-16" src={paymentExpressLogo} alt="logo" />}

            <div className="flex flex-col items-center">
              <Button onClick={close}>Close</Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

let PaymentCallback = () => {
  let location = useLocation();
  let { callback, type = 'mop', amount, DpsTxnRef } = parseQuery(location.search);
  if (!DpsTxnRef) return null;

  let props = { callback, type, amount, DpsTxnRef } as Props;

  return <PaymentCallbackModal {...props} />;
};

export { PaymentCallback };
